<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { useTariff } from '~/store/tariff'
import { useBranchStore } from '~/store/branches'
import { createRequired } from '~/helpers/i18n-validators'

const { $i18n, $toast } = useNuxtApp()
const tariff = useTariff()
const store = useBranchStore()
const { branch } = storeToRefs(store)
const { required, minLength, checkPhoneLength } = createRequired($i18n)

const isLoading = ref(false)
const rules = computed(() => {
  return {
    name: { required, minLength },
    phone: { required, checkPhoneLength },
  }
})
const freemiumData = reactive({
  name: '',
  phone: '',
})
const v$ = useVuelidate(rules, freemiumData)
const route = useRouter().currentRoute

function resetForm() {
  freemiumData.name = ''
  freemiumData.phone = ''
}

async function sendForm() {
  isLoading.value = true
  v$.value.$validate()
  if (!v$.value.$error) {
    await $fetch
      .raw('https://crm.rang.ai/webhooks/forms/', {
        method: 'POST',
        mode: 'no-cors',
        body: {
          name: freemiumData.name,
          phone: freemiumData.phone,
          branch_name: route.value.params.id ? branch.value.name : tariff.otherBranch.name,
          branch_id: route.value.params.id ? branch.value.id : tariff.otherBranch.id,
          form_type: 5,
        },
      })
      .then(() => {
        $toast.success('Заявка создана, спасибо!')
        tariff.isVisibleForm = false
      })
      .catch((err) => {
        $toast.error('Возникла ошибка, попробуйте позже')
        console.error(err)
      })
  }
  isLoading.value = false
}

watch(() => tariff.isVisibleForm, (val) => {
  if (!val) {
    // timeouting reset for animation
    setTimeout(() => {
      resetForm()
    }, 500)
  }
  else {
    v$.value.$reset()
  }
})
</script>

<template>
  <RForm
    v-model:is-visible="tariff.isVisibleForm"
    title="Максимум возможностей<br>с новым тарифом!"
    sub-title="Мы свяжемся с вами для подключения тарифа 👌"
  >
    <form class="flex flex-col gap-3 items-start" @submit.prevent="sendForm">
      <RInput
        v-model:value="freemiumData.name"
        width="450px"
        class="w-full"
        type="text"
        placeholder="Имя"
        label-position="top"
        :validation="v$.name"
        icon="uil:user"
      />
      <RInput
        v-model:value="freemiumData.phone"
        width="450px"
        class="w-full"
        type="tel"
        :validation="v$.phone"
        label-position="top"
        icon="ic:baseline-phone-android"
      />
      <RButton type="submit" :is-loading="isLoading" color="secondary">
        Отправить
      </RButton>
    </form>
  </RForm>
</template>
